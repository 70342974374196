<template>
  <div>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :footer-props="footerProps"
        :items="sponsors"
        :loading="isLoading"
        :server-items-length="sponsorsCount"
        :options.sync="options"
        sort-desc
      >
        <template v-slot:item.attributes.name="{ item }">
          <router-link
            class="view-link"
            :to="{ name: 'SponsorsView', params: { id: item.id } }"
          >
            {{ item.attributes.name }}
          </router-link>
        </template>
        <template v-slot:item.attributes.activeEngagements="{ item }">
          {{ item.attributes.activeEngagements }}
        </template>
        <template v-slot:item.attributes.totalEngagements="{ item }">
          {{ item.attributes.totalEngagements }}
        </template>
        <template v-slot:item.attributes.totalSites="{ item }">
          {{ item.attributes.totalSites }}
        </template>
        <template v-slot:item.attributes.totalReferrals="{ item }">
          {{ item.attributes.totalReferrals }}
        </template>
        <template v-slot:item.attributes.totalScreenedReferrals="{ item }">
          {{ item.attributes.totalScreenedReferrals }}
        </template>
        <template v-slot:item.attributes.totalRandomizedReferrals="{ item }">
          {{ item.attributes.totalRandomizedReferrals }}
        </template>
        <template v-slot:item.attributes.isActive="{ item }">
          <span>
            <v-icon v-if="item.attributes.isActive" color="green"
              >mdi-check-circle</v-icon
            >
            <v-icon v-else color="red">mdi-cancel</v-icon>
          </span>
        </template>
        <template v-slot:no-data>
          <no-data-alert />
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";

export default {
  name: "SponsorsIndex",
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: "Name",
          value: "attributes.name",
        },
        {
          text: "Active Engagements",
          value: "attributes.activeEngagements",
        },
        {
          text: "Total Engagements",
          value: "attributes.totalEngagements",
        },
        {
          text: "Sites",
          value: "attributes.totalSites",
        },
        {
          text: "Referrals",
          value: "attributes.totalReferrals",
        },
        {
          text: "Screened Referrals",
          value: "attributes.totalScreenedReferrals",
        },
        {
          text: "Randomized Referrals",
          value: "attributes.totalRandomizedReferrals",
        },
        {
          text: "Status",
          value: "attributes.isActive",
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [false],
      },
    };
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.isLoading = true;
        await this.getSponsors(currentValue);
        this.isLoading = false;
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["footerProps"]),
    ...mapState("sponsors", ["sponsors", "sponsorsCount"]),
    ...mapGetters(["acceptRoles"]),
    showSponsorAddButton() {
      return this.acceptRoles(["Manager", "Admin", "Super User"]);
    },
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      const desc = this.options.sortDesc[0] === true ? "-" : "";

      // adjust sort data only if it was changed
      if (this.options.sortBy.length) {
        let sortField = this.options.sortBy[0].substring(11);
        data.sortBy = [desc + sortField];
      }

      return data;
    },
  },
  components: {
    NoDataAlert,
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("sponsors", ["clearSponsors", "getSponsors"]),
  },
  async mounted() {
    if (!this.sponsors.length) {
      this.isLoading = true;
      await this.getSponsors();
      this.isLoading = false;
    }
    this.setBreadcrumbs();
    this.setTitleObject("Sponsors");
    let actionButtons = [];
    if (this.showSponsorAddButton) {
      actionButtons = [
        {
          attributes: {
            to: "/sponsors/add",
          },
          component: "AddButton",
          text: "Add Sponsor",
          index: 0,
        },
      ];
    }
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearSponsors();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped></style>
